import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Client = () => {
    const slides = [
        { id: 1, imageUrl: 'https://agamskills.com/images/New-Images/Picture1.png' },
        { id: 2, imageUrl: 'https://agamskills.com/images/New-Images/Picture2.png' },
        { id: 3, imageUrl: 'https://agamskills.com/images/New-Images/Picture3.png' },
        { id: 4, imageUrl: 'https://agamskills.com/images/New-Images/Picture4.png' },
        { id: 5, imageUrl: 'https://agamskills.com/images/New-Images/Picture5.png' },
        { id: 6, imageUrl: 'https://agamskills.com/images/New-Images/Picture6.png' },
    ];

    // Slick settings
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        centerMode:true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerPadding: '10px',
        draggable: true,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '50px',
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <>
            <section id="client" className="client">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        {/* <!-- <h2>Our Partners</h2> --> */}
                        <p>Our Partners</p>
                    </div>
                    <Slider {...settings}>
                        {slides.map((slide) => (
                            <div key={slide.id}>
                                <div className="testimonial-wrap">
                                    <div className="client-img-logo">
                                        <img src={slide.imageUrl} className="img-fluid" alt={`Slide ${slide.id}`} loading='lazy' />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        </>
    );
};

export default Client;

