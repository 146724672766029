import React from 'react'
import Counts from './Components/Counts'
import VisionMission from './Components/VisionMission'
import Testimonials from './Components/Testimonials'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import { Helmet } from 'react-helmet'

function About() {
    return (
        <>
            <Helmet>
                <title>About Us || City Translink ||</title>
            </Helmet>
            <ScrollToTopOnMount />
            <main id="main">
                {/* <!-- ======= Breadcrumbs ======= --> */}
                <div className="breadcrumbs" data-aos="fade-in">
                    <div className="container">
                        <h1>About Us</h1>
                    </div>
                </div>
                {/* <!-- End Breadcrumbs --> */}

                {/* <!-- ======= About Section ======= --> */}
                <section id="about" className="about">
                    <div className="container" data-aos="fade-up">
                        <h3 className='text-center mb-5'>Our Organization Profile</h3>
                        <div className="row my-5">
                            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                                <p>
                                    City Translink, a government-recognized organization, is committed to conducting comprehensive assessments for individuals,
                                    including employees, mentors, students, educators, and skilled workers, focusing on various aspects such as behavior, skills,
                                    communication, knowledge, awareness, interests, and environmental factors. Our use of diverse techniques ensures a transparent,
                                    fair, and unbiased evaluation process, facilitating equitable scaling to empower individuals in addressing weaknesses,
                                    amplifying their interests, and fostering holistic development.
                                </p>
                                <p>
                                    As the world rapidly evolves, the education sector is no exception, necessitating a shift towards continuous learning.
                                    Next-generation education must be adaptive, progressive, and aligned with the dynamic needs of students,
                                    aiming for increased effectiveness and efficiency. Embracing tools like blended learning can play a pivotal role in achieving these goals.
                                </p>
                            </div>
                            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                                <img src='assets/img/about-section-1.jpg' className='img-fluid shadow' alt='about' loading='lazy' />
                            </div>
                        </div>
                        <div className="row my-5">
                            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                                <img src='assets/img/about-section-2.jpg' className='img-fluid shadow' alt='about' loading='lazy' />
                            </div>
                            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                                <p>
                                    At CTPL, we nurture mentors, students, educators, and skilled workers through our assessments,
                                    furnishing them with scorecards to equip them for any professional opportunity. Additionally,
                                    our collaborations with industries extend to OJT and apprenticeship programs, complemented by
                                    job assistance initiatives, ensuring a brighter future for our candidates.
                                </p>
                                <p>
                                    Furthermore, we guarantee the deployment of a suitable number of certified assessors with Training of Assessor (TOA)
                                    accreditation across diverse regions and sectors. Their responsibilities include ensuring the prompt receipt of login credentials.
                                    Leveraging research-backed assessments, an advanced cloud platform, and comprehensive analytics, we deliver transformative
                                    outcomes for our clients and their workforce. Our services encompass tailored assessments throughout the employee lifecycle,
                                    spanning pre-hiring screenings, candidate skills assessments, employee/student training and development programs, certification exams, contests, and more.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- End About Section --> */}

                <Counts />
                <VisionMission />
                <Testimonials />
            </main>
        </>
    )
}

export default About
