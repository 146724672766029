import React from 'react'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import { Helmet } from 'react-helmet'

function Contact() {
    return (
        <>
            <Helmet>
                <title>Contact Us || City Translink ||</title>
            </Helmet>
            <ScrollToTopOnMount />
            <main id="main">
                {/* <!-- ======= Breadcrumbs ======= --> */}
                <div className="breadcrumbs" data-aos="fade-in">
                    <div className="container">
                        <h1>Contact Us</h1>
                    </div>
                </div>
                {/* <!-- End Breadcrumbs --> */}

                {/* <!-- ======= Contact Section ======= --> */}
                <section id="contact" className="contact">
                    <div data-aos="fade-up">
                        <iframe title='map' style={{ border: '0', width: '100%', height: '350px' }} allowFullScreen loading='lazy' 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d741.9271961722212!2d72.99873559092514!3d19.065161844447328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c14e81196553%3A0x123d2dd78cf51380!2sINFOTECH%20PARK%2C%20Sector%2030A%2C%20Vashi%2C%20Navi%20Mumbai%2C%20Maharashtra%20400703!5e0!3m2!1sen!2sin!4v1703154774111!5m2!1sen!2sin">
                        </iframe>
                    </div>

                    <div className="container" data-aos="fade-up">
                        <div className="row mt-5">
                            <div className="col-lg-4">
                                <div className="info">
                                    <div className="address">
                                        <i className="bi bi-geo-alt"></i>
                                        <h4>Location:</h4>
                                        <p>405, Vashi Infotech Park, Plot No. 16,<br /> Sector 30A, Vashi, Navi Mumbai- 400703, <br /> Maharashtra, India.</p>
                                    </div>

                                    <div className="email">
                                        <i className="bi bi-envelope"></i>
                                        <h4>Email:</h4>
                                        <p>info@citytranslink.com</p>
                                    </div>

                                    <div className="phone">
                                        <i className="bi bi-phone"></i>
                                        <h4>Mobile:</h4>
                                        <p>+91 9324121410</p>
                                    </div>

                                    <div className="phone">
                                        <i className="bi bi-telephone"></i>
                                        <h4>Telephone:</h4>
                                        <p>22-40220707</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 mt-5 mt-lg-0">
                                <form action="" method="" className="php-email-form">
                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                                        </div>
                                        <div className="col-md-6 form-group mt-3 mt-md-0">
                                            <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                        </div>
                                    </div>
                                    <div className="form-group mt-3">
                                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                                    </div>
                                    <div className="form-group mt-3">
                                        <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                                    </div>
                                    <div className="my-3">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Your message has been sent. Thank you!</div>
                                    </div>
                                    <div className="text-center"><button type="submit">Send Message</button></div>
                                </form>

                            </div>

                        </div>

                    </div>
                </section>
                {/* <!-- End Contact Section --> */}
            </main>
        </>
    )
}

export default Contact
