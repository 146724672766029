import React from 'react'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import { Helmet } from 'react-helmet'

function RequestDemo() {
    return (
        <>
            <Helmet>    
                <title>Request Demo || City Translink ||</title>
            </Helmet>
            <ScrollToTopOnMount/>
            <main id="main">
                {/* <!-- ======= Breadcrumbs ======= --> */}
                <div className="breadcrumbs" data-aos="fade-in">
                    <div className="container">
                        <h1>Request Demo</h1>
                    </div>
                </div>
                {/* <!-- End Breadcrumbs --> */}

                {/* <!-- ======= Contact Section ======= --> */}
                <section id="contact" className="contact">
                    <div className="container" data-aos="fade-up">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-lg-10 mt-5 mt-lg-0">
                                <form action="" method="" className="php-email-form">
                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <label for="name" class="form-label">First Name <span>*</span></label>
                                            <input type="text" name="name" className="form-control" id="name" placeholder="" required />
                                        </div>
                                        <div className="col-md-6 form-group mt-3 mt-md-0">
                                            <label for="lname" class="form-label">Last Name </label>
                                            <input type="text" name="lname" className="form-control" id="lname" placeholder="" />
                                        </div>
                                    </div>
                                    <div className="form-group mt-3">
                                        <label for="subject" class="form-label">Subject <span>*</span></label>
                                        <input type="text" className="form-control" name="subject" id="subject" placeholder="" required />
                                    </div>
                                    <div className="form-group mt-3">
                                        <label for="email" class="form-label">Your Email <span>*</span></label>
                                        <input type="email" className="form-control" name="email" id="email" placeholder="" required />
                                    </div>
                                    <div className="form-group mt-3">
                                        <label for="message" class="form-label">Query/Message <span>*</span></label>
                                        <textarea className="form-control" name="message" id='message' rows="5" placeholder="" required></textarea>
                                    </div>
                                    <div className="my-3">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Your message has been sent. Thank you!</div>
                                    </div>
                                    <div className="text-center"><button type="submit">Send Message</button></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- End Contact Section --> */}
            </main>
        </>
    )
}

export default RequestDemo
